<style>
  h2 {
    font-family: 'IBM Plex Sans';
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
</style>

<h2>
  <slot />
</h2>
